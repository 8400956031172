<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-4">

      <div class="col-md-3 my-3">
        <label for="filterFilterDepartments">Фильтрация по кафедрам</label>
        <select class="form-control form-select" id="filterFilterDepartments" v-model="departmentId">
          <option v-for="(item, index) in departments" :value="item.id" :key="index">
            {{item.name_ru}}
          </option>
        </select>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th>№</th>
            <th>Уровень</th>
            <th>Язык</th>
            <th>Дисциплины (FRONT)</th>
            <th>Дисциплины (OLDMOODLE)</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(discipline, disciplineIndex) in disciplines" :key="disciplineIndex">
            <td>{{disciplineIndex+1}}</td>
            <td>{{discipline.studyLevel.name}}</td>
            <td>{{discipline.language.native_name}}</td>
            <td>{{discipline.name}}</td>
            <td>
              <!--              <Dropdown :options="oldDisciplines" optionLabel="name" :filter="true" placeholder="Выберите дисциплину"-->
              <!--                        :showClear="true">-->
              <!--                <template #value="slotProps">-->
              <!--                  <div class="country-item country-item-value" v-if="slotProps.value">-->
              <!--                    <div>{{slotProps.value.discipline_name}}</div>-->
              <!--                  </div>-->
              <!--                  <span v-else>-->
              <!--                    {{slotProps.placeholder}}-->
              <!--                </span>-->
              <!--                </template>-->
              <!--                <template #option="slotProps">-->
              <!--                  <div class="country-item">-->
              <!--                    <div>{{slotProps.option.discipline_name}}</div>-->
              <!--                  </div>-->
              <!--                </template>-->
              <!--              </Dropdown>-->
              <div>
                <select class="form-select" @change="changeOldDiscipline($event, discipline.id)">
                  <option v-for="(item, index) in [{discipline_name: 'Выберите дисциплину'}, ...oldDisciplines]"
                          :value="item.discipline_name"
                          :key="index">
                    {{item.discipline_name}}
                  </option>
                </select>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="text-center my-4">
        <button class="btn btn-primary" @click="save"
                :disabled="updateStatus">
          <span v-if="updateStatus" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          {{edit?'Сохранение':'Сохранить'}}
        </button>
      </div>


    </div>
  </div>
</template>


<script>
  import httpClient from "@/services/http.service"

  export default {
    name: 'OldDisciplineBindToNewDiscipline',
    data() {
      return {
        loading: true,
        departmentId: 0,
        disciplines: [],
        departments: [],
        oldDisciplines: [],
        updateStatus: false

      }
    },
    computed: {},
    methods: {
      async GET_DEPARTMENTS() {
        try {
          const {status, data} = await httpClient.get(`admin/departments/index`);
          if (status === 200) {
            this.departments = data.success
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
      },
      async GET_DISCIPLINES_BY_DEPARTMENT_ID(department_id) {
        try {
          const {status, data} = await httpClient.get(`education_program/education-discipline/get-disciplines-by-department?department_id=${department_id}`)
          if (status === 200) {
            this.disciplines = data
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
      },
      async GET_OLD_DISCIPLINES_BY_DEPARTMENT_ID(department_id) {
        try {
          const {status, data} = await httpClient.get(`old_ratings/old-student-ratings/get-old-moodle-discipline-name?department_id=${department_id}`)
          if (status === 200) {
            this.oldDisciplines = data
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
      },
      async GET_PUT_DISCIPLINE_ID(putData) {
        try {
          const {status, data} = await httpClient.put(`old_ratings/old-student-ratings/update`, putData)
          if (status === 200) {
            console.log('ok')
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
      },
      changeOldDiscipline(e, discipline_id) {
        let item = this.oldDisciplines.find(i => i.discipline_name === e.target.value)
        item['discipline_id'] = discipline_id
      },
      async save() {
        this.updateStatus = true
        let putData = this.oldDisciplines.filter(i => !!i.discipline_id)
        await this.GET_PUT_DISCIPLINE_ID(putData)
        this.$message({text: `Данные успешно сохранились`})
        console.log(putData)
        this.updateStatus = false
      }
    },
    async mounted() {
      await this.GET_DEPARTMENTS()
      this.loading = false
    },
    watch: {
      async departmentId(before, current) {
        this.loading = true
        await this.GET_DISCIPLINES_BY_DEPARTMENT_ID(this.departmentId)
        await this.GET_OLD_DISCIPLINES_BY_DEPARTMENT_ID(this.departmentId)
        this.loading = false
      }
    }
  }
</script>

<style scoped>

</style>